<template>
  <section class="all-cases-view">
    <div class="navbarComponent">
      <div class="container-default">
        <div class="listings-navbar">
          <el-row gutter="20">
            <el-col :lg="14" :xl="14">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li
                  v-if="
                    selectedCaseIds.length &&
                    hasPermission('generate_barcode', 'view')
                  "
                >
                  <el-button
                    class="btn button-with-icon"
                    auto-insert-space
                    @click="onPrintBarCodes"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/printer-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                      <img
                        class="inactive"
                        src="@/assets/images/printer-icon-black.svg"
                        alt="icon"
                      />
                    </span>
                    <span>PRINT BARCODE</span>
                  </el-button>
                </li>
                <li
                  v-if="
                    !selectedCaseIds.length &&
                    hasPermission('generate_barcode', 'view')
                  "
                >
                  <el-button
                    class="btn button-with-icon"
                    @click="generateBarcodesDialogVisible = true"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/new-barcode-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                      <img
                        class="inactive"
                        src="@/assets/images/new-barcode-icon.svg"
                        alt="icon"
                      />
                    </span>
                    <span>GENERATE BARCODE</span>
                  </el-button>
                </li>
                <li
                  v-if="
                    selectedCaseIds.length &&
                    hasPermission('generate_barcode', 'view')
                  "
                >
                  <el-button
                    class="btn button-with-icon"
                    auto-insert-space
                    @click="onPrintQrCodes"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/printer-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                      <img
                        class="inactive"
                        src="@/assets/images/printer-icon-black.svg"
                        alt="icon"
                      />
                    </span>
                    <span>PRINT QRCODE</span>
                  </el-button>
                </li>
                <li
                  v-if="
                    !selectedCaseIds.length &&
                    hasPermission('generate_barcode', 'view')
                  "
                >
                  <el-button
                    class="btn button-with-icon"
                    @click="generateQrcodesDialogVisible = true"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/new-barcode-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                      <img
                        class="inactive"
                        src="@/assets/images/new-barcode-icon.svg"
                        alt="icon"
                      />
                    </span>
                    <span>GENERATE QRCODE</span>
                  </el-button>
                </li>
                <li>
                  <div class="form-element input-with-icon search-input-lg">
                    <el-input
                      placeholder="Accession / Patient Name"
                      v-model="search_string"
                      @input="getAutoCompleteCases()"
                      :clearable="true"
                    ></el-input>
                    <span>
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid icon"
                      />
                    </span>
                  </div>
                </li>

                <!-- <li
                  v-if="
                    bulkValidationData.length &&
                    hasPermission('validations', 'cases')
                  "
                >
                  <el-button
                    class="btn button-with-icon"
                    @click="moveBulkValidation()"
                    >Bulk Validations</el-button
                  >
                </li> -->
              </ul>
            </el-col>
            <el-col :lg="10" :xl="10">
              <ul
                class="d-flex flex-wrap justify-content-end list-items-group-15"
              >
                <li>
                  <div
                    class="form-element search-with-filter-input-without-icon"
                  >
                    <el-select
                      v-model="filter_value"
                      placeholder="Select"
                      filterable
                      @change="fetchAllCases()"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="container-default" style="padding-top: 0">
      <div class="primary-content-view-new" v-loading="loading">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="getAllCriticalCases.data"
            height="calc(100vh - 200px)"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
            @selection-change="handleSelectionChange($event)"
          >
            <el-table-column
              type="selection"
              width="40"
              class="selection-check"
            ></el-table-column>
            <el-table-column
              fixed="left"
              prop="accession_id"
              label="ACCESSION ID"
              min-width="120"
            >
              <template #default="scope">
                <div class="name-accession">
                  <span
                    id="accession-id"
                    @click="goToCaseView(scope.row.case_id)"
                  >
                    {{ scope.row.accession_id }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="patient_info.first_name"
              label="PATIENT NAME"
              min-width="140"
              fixed="left"
            >
              <template #default="scope">
                <el-button placement="top" class="title-on-hover">
                  <span class="text-center ordering_facility-value">{{
                    scope.row.name
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <!-- <el-table-column label="Patient DOB" min-width="100">
              <template #default="scope">
                <span v-if="scope.row">
                  {{
                    scope.row.date_of_birth
                      ? formatDate(scope.row.date_of_birth)
                      : ""
                  }}</span
                >
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="Gender" min-width="100">
              <template #default="scope">
                <span v-if="scope.row">
                  {{ scope.row.gender ? scope.row.gender : "" }}</span
                >
              </template>
            </el-table-column> -->
            <el-table-column label="Critical Values" min-width="200">
              <template #default="scope">
                <el-popover
                  :width="350"
                  class="popover__headerTitle"
                  style="overflow: hidden"
                  trigger="hover"
                  v-if="scope.row.results"
                >
                  <div class="criticalBlock">
                    <div class="popover__headerTitle">
                      <h3>Critical Values</h3>
                    </div>
                    <div class="criticalTable headers">
                      <p class="eachHeader">Test Names</p>
                      <p class="eachHeader">Flags</p>
                      <p class="eachHeader">Results</p>
                    </div>
                    <div class="tableBodyBlock">
                      <div
                        v-for="test in scope.row.results"
                        :key="test._id"
                        class="criticalTable"
                      >
                        <p class="testName">{{ test.test_name }}</p>
                        <p class="flags">
                          {{ test.flag }}
                        </p>
                        <p class="testResults">
                          {{ test.result }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <template #reference>
                    <span
                      >Critical Values [{{
                        scope.row.results.length || 0
                      }}]</span
                    >
                  </template>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              :width="isSuperAdmin ? '230' : '220'"
              fixed="right"
            >
              <template #default="scope">
                <ul class="listings__action-buttons">
                  <li @click="goToCaseView(scope.row.case_id)">
                    <el-button class="view-button" title="View Case">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      scope.row.is_critical && scope.row.is_resolved == false
                    "
                  >
                    <el-button @click="resolveCase(scope.row.case_id)"
                      >Resolve</el-button
                    >
                  </li>
                  <li v-if="scope.row.is_critical && scope.row.is_resolved">
                    <el-button @click="openNotes(scope.row.notes)"
                      >Notes</el-button
                    >
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout=" prev, pager, next, jumper, sizes ,total"
            :total="getAllCriticalCases.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div class="default-modal">
      <div v-if="generateBarcodesDialogVisible">
        <el-dialog
          top="10%"
          width="35%"
          v-model="generateBarcodesDialogVisible"
        >
          <div class="dialog-header">
            <span class="header-part">
              <img
                src="@/assets/images/new-barcode-icon-blue.svg"
                alt="icon"
              />Generate Barcodes
            </span>
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="generateBarcodesDialogVisible = false"
            >
              <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
            /></el-button>
          </div>

          <el-row :gutter="20" class="popup-date">
            <el-col :lg="8" :xl="9">
              <span class="date-label">From Date</span>
              <el-date-picker
                type="date"
                v-model="barcode_receive_from_date"
                placeholder="From Date"
                format="MM-DD-YYYY"
                :disabled-date="disabledDate"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-col>
            <el-col :lg="8" :xl="9">
              <span class="date-label">To Date</span>
              <el-date-picker
                type="date"
                v-model="barcode_receive_to_date"
                placeholder="To Date"
                format="MM-DD-YYYY"
                :disabled-date="disabledDate"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-col>
            <el-col :lg="8" :xl="6">
              <div class="dialog-footer mt-15">
                <el-button
                  class="blue-save-button"
                  @click="onSelectedDatesGenerateBarcodes"
                  :disabled="isDateAvailable"
                >
                  GENERATE</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
    </div>
    <div class="default-modal">
      <div v-if="generateQrcodesDialogVisible">
        <el-dialog top="10%" width="35%" v-model="generateQrcodesDialogVisible">
          <div class="dialog-header">
            <span class="header-part">
              <img
                src="@/assets/images/new-barcode-icon-blue.svg"
                alt="icon"
              />Generate Qrcodes
            </span>
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="generateQrcodesDialogVisible = false"
            >
              <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
            /></el-button>
          </div>

          <el-row :gutter="20" class="popup-date">
            <el-col :lg="8" :xl="9">
              <span class="date-label">From Date</span>
              <el-date-picker
                type="date"
                v-model="qrcode_receive_from_date"
                placeholder="From Date"
                format="MM-DD-YYYY"
                :disabled-date="disabledDate"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-col>
            <el-col :lg="8" :xl="9">
              <span class="date-label">To Date</span>
              <el-date-picker
                type="date"
                v-model="qrcode_receive_to_date"
                placeholder="To Date"
                format="MM-DD-YYYY"
                :disabled-date="disabledDate"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-col>
            <el-col :lg="8" :xl="6">
              <div class="dialog-footer mt-15">
                <el-button
                  class="blue-save-button"
                  @click="onSelectedDatesGenerateQrcodes"
                  :disabled="isQrDateAvailable"
                >
                  GENERATE</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
    </div>
    <div class="default-modal">
      <el-dialog
        top="25vh"
        width="36.5%"
        v-model="openResolvePopup"
        @close="close"
        :close-on-click-modal="false"
      >
        <div class="dialog-header">
          <span class="header-part"> Notes </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="close"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date pb-0">
          <el-form ref="ruleForm" :model="formData">
            <el-form-item prop="reason">
              <el-input
                type="textarea"
                v-model="formData.notes"
                rows="3"
                placeholder="Enter notes"
              />
            </el-form-item>
          </el-form>
          <div class="dialog-footer pt-7">
            <el-button
              class="blue-save-button"
              :disabled="checkReason"
              @click="submitForm"
              :loading="resolveLoading"
              >SUBMIT</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="default-modal">
      <el-dialog
        v-model="dialogVisible"
        top="25vh"
        width="36.5%"
        :before-close="handleClose"
      >
        <div class="dialog-header">
          <span class="header-part"> Notes </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="handleClose"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date">
          <span>{{ this.caseNotes }}</span>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
import FilterHelper from "@/mixins/FilterHelper";
import AppHelper from "@/mixins/AppHelper";

import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import config from "@/config/app";
export default {
  name: "allCases",

  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUserAllowedLabs",
      "getUserPrimaryLabId",
    ]),
    isDateAvailable() {
      if (!this.barcode_receive_to_date) {
        return true;
      }
      return false;
    },
    isQrDateAvailable() {
      if (!this.qrcode_receive_to_date) {
        return true;
      }
      return false;
    },

    ...mapGetters("cases", [
      "getAllCriticalCases",
      "getCaseResolveError",
      "getCaseResolveStatus",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    checkReason() {
      if (
        this.formData.notes &&
        this.formData.notes.trim() &&
        this.formData.notes.trim().length > 1
      ) {
        return false;
      }

      return true;
    },
  },
  mixins: [FilterHelper, UserPermissionsHelper, AppHelper, caseDataHelper],
  data() {
    return {
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      scrollPosition: null,
      generateBarcodesDialogVisible: false,
      barcode_receive_from_date: "",
      barcode_receive_to_date: "",
      generateQrcodesDialogVisible: false,
      qrcode_receive_from_date: "",
      qrcode_receive_to_date: "",
      page: 1,
      pageSize: 25,
      search_string: "",
      loading: false,
      orderBy: "created_at",
      orderType: "DESC",
      selectedCaseIds: [],
      showComponent: false,
      options: [
        {
          value: "critical",
          label: "Is Critical",
        },
        {
          value: "resolved",
          label: "Resolved",
        },
      ],
      filter_value: "critical",
      resolveCaseId: "",
      openResolvePopup: false,
      formData: {
        notes: "",
      },
      resolveLoading: false,
      caseNotes: "",
      dialogVisible: false,
    };
  },

  async mounted() {
    const versionHandled = localStorage.getItem("versionHandled");
    if (!versionHandled) {
      this.handleVersioning();
      // Set the flag in local storage to indicate that handleVersioning has been called
      localStorage.setItem("versionHandled", "true");
    }
    if (
      this.$route.query.showPopup &&
      (this.$route.path === "/all-cases" ||
        this.$route.path === "/all-cases/new-reports") &&
      !localStorage.getItem("componentShown") &&
      window.location.href.indexOf("app.labsquire.com") > -1
    ) {
      this.showComponent = true;
      setTimeout(() => {
        this.showComponent = false;
      }, 30000);
      // Set a flag in local storage to indicate that the component has been shown
      localStorage.setItem("componentShown", "true");
    }
    setTimeout(() => {
      this.showComponent = false;
    }, 30000);
    await this.initData();
    // Add Class to body on scroll
    window.addEventListener("Noscroll", this.onScroll);
  },
  methods: {
    getPatientName(data) {
      if (!data) return;
      let name = [];
      if (data.first_name) {
        name.push(data.first_name);
      }
      if (data.middle_name) {
        name.push(data.middle_name);
      }
      if (data.last_name) {
        name.push(data.last_name);
      }
      return name.join(" ");
    },
    handleVersioning() {
      const currentVersion = config.APP_VERSION;
      const currentAppName = config.APP_NAME;
      const versionKey = "APP_VERSION";
      const appName = "APP_NAME";
      if (localStorage.getItem(versionKey) !== currentVersion) {
        localStorage.clear();
        localStorage.setItem(versionKey, currentVersion);
        localStorage.setItem(appName, currentAppName);
        window.location.reload(true);
      }
    },
    initializeSearchOptions() {
      this.searchOptions = [...this.searchOptions];
      this.filterSearchOptions();
    },

    async onScroll() {
      // Get the scroll position, you can adjust this value based on your requirement
      const scrollPosition = window.scrollY;
      // Add a class to the body element based on the scroll position
      // For example, adding a class 'scrolled' when the user scrolls more than 100px
      if (scrollPosition > 1) {
        document.body.classList.add("fixedNavbarOnComponent");
      } else {
        document.body.classList.remove("fixedNavbarOnComponent");
      }
    },

    onSelectedDatesGenerateBarcodes() {
      if (
        this.barcode_receive_from_date != "" &&
        this.barcode_receive_to_date != ""
      ) {
        let urlData = this.$router.resolve({
          name: "MultipleBarcodes",
          path: "multiple-barcodes",
          query: {
            from_date: this.barcode_receive_from_date,
            to_date: this.barcode_receive_to_date,
          },
        });
        this.barcode_receive_from_date = "";
        this.barcode_receive_to_date = "";
        this.generateBarcodesDialogVisible = false;
        window.open(urlData.href, "_blank");
      }
    },
    onSelectedDatesGenerateQrcodes() {
      if (
        this.qrcode_receive_from_date != "" &&
        this.qrcode_receive_to_date != ""
      ) {
        let urlData = this.$router.resolve({
          name: "MultipleQrcodes",
          path: "multiple-qrcodes",
          query: {
            from_date: this.qrcode_receive_from_date,
            to_date: this.qrcode_receive_to_date,
          },
        });
        this.qrcode_receive_from_date = "";
        this.qrcode_receive_to_date = "";
        this.generateQrcodesDialogVisible = false;
        window.open(urlData.href, "_blank");
      }
    },

    async initData() {
      this.setQueryParamsData();
      await Promise.all([this.fetchAllCases()]);
    },

    setQueryParamsData() {
      this.page = this.$route.query.selectedPage
        ? parseInt(this.$route.query.selectedPage)
        : 1;
      this.pageSize = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 25;
      if (this.$route.query.selectedString) {
        this.search_string = this.$route.query.selectedString;
      }

      if (this.$route.query.selectedCaseOrderBy) {
        this.orderBy = this.$route.query.selectedCaseOrderBy;
      }
      if (this.$route.query.selectedCaseOrderType) {
        this.orderType = this.$route.query.selectedCaseOrderType;
      }
    },

    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }

      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.filter_value == "critical") {
        params.is_critical = true;
      } else {
        params.is_critical = false;
      }
      if (this.filter_value == "resolved") {
        params.is_resolved = true;
        params.is_critical = true;
      } else {
        params.is_resolved = false;
      }
      return params;
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchAllCases();
    },
    getAutoCompleteCases() {
      setTimeout(() => {
        this.page = 1;
        this.fetchAllCases();
      }, 600);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllCases();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllCases();
    },
    handleCurrentChangeCaseType(page) {
      this.page = page;
      this.test_info = [];
      this.fetchAllCases();
    },

    async fetchAllCases() {
      try {
        let params = this.prepareParams();
        this.loading = true;
        await this.$store.dispatch("cases/fetchCriticalCasesPage", params);
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    handleSelectionChange(rows) {
      if (rows) {
        this.selectedCaseIds = [];
        this.bulkValidationData = [];
        for (let caseData of rows) {
          this.selectedCaseIds.push(caseData._id);
          this.bulkValidationData.push(caseData);
        }
      } else {
        this.selectedCaseIds = [];
        this.bulkValidationData = [];
      }
    },
    goToCaseView(caseId) {
      this.$router.push({
        name: "CaseView",
        params: { case_id: caseId },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },

    updateQueryParams(params) {
      let selectedParams = {
        selectedPage: params.page,

        selectedString: params.search_string,

        selectedPageSize: params.limit,

        selectedCaseOrderBy: params.order_by,
        selectedCaseOrderType: params.order_type,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },

    onPrintBarCodes() {
      let urlData = this.$router.resolve({
        name: "MultipleBarcodes",
        path: "multiple-barcodes",
        query: {
          case_ids: this.selectedCaseIds,
        },
      });
      window.open(urlData.href, "_blank");
    },
    onPrintQrCodes() {
      let urlData = this.$router.resolve({
        name: "MultipleQrcodes",
        path: "multiple-qrcodes",
        query: {
          case_ids: this.selectedCaseIds,
        },
      });
      window.open(urlData.href, "_blank");
    },
    resolveCase(id) {
      this.resolveCaseId = id;
      this.openResolvePopup = true;
    },
    close() {
      this.openResolvePopup = false;
      this.formData = {};
    },
    async submitForm() {
      try {
        this.resolveLoading = true;
        await this.$store.dispatch("cases/resolveCase", {
          case_id: this.resolveCaseId,
          notes: this.formData.notes,
          is_resolved: true,
        });
        if (this.getCaseResolveStatus) {
          this.openResolvePopup = false;
          successNotification("Case Resolved successfully");
          this.resolveCaseId = "";
          await this.fetchAllCases();
        } else {
          errorNotification(
            this.getCaseResolveError || "Error while deleting case"
          );
        }

        this.resolveLoading = false;
      } catch (err) {
        this.resolveLoading = false;
        console.log(err);
      }
    },
    async openNotes(note) {
      this.dialogVisible = true;
      this.caseNotes = note;
    },
    handleClose() {
      this.caseNotes = "";
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
.test-reports {
  display: flex;
  align-items: center;
  padding: 4px 5px;
  .regular-reports {
    &.single-report {
      margin-left: 20px;
    }
    h5 {
      color: #0a5da6;
      font-size: 14px;
      span {
        color: #bdbdbd;
      }
    }
  }
  .repots {
    h5 {
      color: #0a5da6;
    }
  }
}

.stickyNavbar {
  padding-bottom: 5px !important;
  .container-default {
    padding-top: 0;
  }
}

.test-tabs {
  padding: 0;

  .el-tabs__active-bar {
    height: 3px;
    background-color: #0a5da6;
    border-radius: 25px;
  }
  .el-tabs__item.is-active {
    color: #0a5da6;
    font-weight: 600;
  }

  .el-tabs__item {
    color: #828282;
  }
  .el-tabs__nav-scroll {
    padding: 0 18px;
  }

  .types {
    padding: 0 5px;
    .case-type {
      &.view-reports-dialog {
        .el-button {
          width: 100%;
          min-height: 36px;
          padding: 0 17px;
          background-color: #f2f6f9;
          border: 1px dashed #bababc;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-right: 6px;
            }
            p {
              margin-left: 8px;
              color: #828282;
            }
          }
        }
      }
      .el-input__inner {
        height: 36px;
        border: 1px solid rgba(86, 204, 242, 0.8);
      }
    }
    .btn {
      margin-top: 23px;
      .el-button {
        min-height: 36px;
        padding: 8px 24px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 7px;
      }
    }
  }
  .mark {
    margin-left: 15px;
  }
  .panel {
    .documents {
      display: none;
    }
    .el-table__header {
      color: rgba(0, 0, 0, 0.8);
      border-spacing: 0 5.5px;
      border-radius: 25px;
    }
    .el-table {
      background: rgb(238, 242, 245);
      .el-table__cell {
        padding: 5px 0;
      }
      .el-table__header-wrapper {
        border-radius: 10px;
      }
      .el-table__body-wrapper {
        border-radius: 6px;
      }
      .el-table__cell {
        .cell {
          font-weight: 500;
        }
      }
    }
    .el-table thead {
      color: rgba(0, 0, 0, 0.8);
    }
    .physician-btn {
      .el-switch .el-switch__core {
        width: 34px !important;
        height: 15px;
        background-color: #0a5da6;
        border: none;
        .el-switch__action {
          top: 3px;
          left: 2px;
          width: 9px;
          height: 9px;
        }
      }
      .el-switch__label.is-active {
        color: #0a5da6;
      }
      .el-switch.is-checked {
        .el-switch__core {
          width: 34px !important;
          height: 15px;
          background-color: #4bb543 !important;
          border: none;
        }
        .el-switch__action {
          top: 2.2px;
          left: 38.5px;
          width: 10px;
          height: 10px;
        }
      }
    }
    .title {
      color: #2f80ed;
      img {
        color: #eb5757;
        vertical-align: middle;
      }
    }
  }
  .action-test-icons {
    display: flex;
    li {
      margin-right: 10px;
      .el-button {
        min-height: auto;
        padding: 0;
        background: none;
        border: none;
        transition: all 0.2s;

        &:hover {
          background-color: #eef2f5;
        }
        &:active {
          background-color: #eef2f5;
        }
        &:focus {
          background-color: #eef2f5;
        }
        img {
          color: red;
          &:hover {
            color: #0a5da6;
          }
        }
      }
    }
  }
}
.documents {
  padding: 0 20px;
  .el-tabs__item.is-active {
    color: #0a5da6;
    font-weight: 500;
  }
  .el-tabs__active-bar {
    background-color: #0a5da6;
    border-radius: 25px;
  }
  .document-container {
    display: grid;
    align-items: center;

    gap: 10px;
    grid-template-columns: 230px 230px;
  }
  .document-cell {
    display: grid;
    align-items: center;
    padding: 0 0.3rem;
    background-color: #fff;
    border: 1px solid #56ccf2;
    border-radius: 7px;

    grid-template-columns: 9% 75% 8% 10%;
    img {
      width: 18px;
      height: 18px;
    }
    .cancel {
      width: 15px;
      height: 15px;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
    }
    .demo-progress {
      .el-progress--line {
        width: 230px;
        .el-progress-bar__outer {
          height: 5px !important;
          background-color: #9ae0f7;
        }
        .el-progress-bar__inner {
          height: 5px;
          background-color: #2d9cdb;
        }
      }
      .el-progress__text {
        color: #828282;
      }
    }
  }
  .document-cell {
    display: grid;
    align-items: center;
    padding: 0 0.3rem;
    background-color: #fff;
    border: 1px solid #56ccf2;
    border-radius: 7px;

    grid-template-columns: 9% 80% 8% 10%;
    img {
      width: 18px;
      height: 18px;
    }
    .cancel {
      width: 15px;
      height: 15px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.listings-navbar {
  .barcode-print {
    .el-button--small {
      padding: 7px 15px;
      border-radius: 8px;
      &:hover {
        color: #4f4f4f;
        background: #fff;
        box-shadow: none;
        border: 1px solid #dedbdb;
      }
      &:focus {
        color: #4f4f4f;
        background: #fff;
        box-shadow: none;
        border: 1px solid #dedbdb;
      }
      &:active {
        color: #4f4f4f;
        background: #fff;
        box-shadow: none;
        border: 1px solid #dedbdb;
      }
    }
  }
}
.btn-synced {
  min-width: 100px;
  height: auto;
  min-height: 20px;
  padding: 6px 5px;
  color: #4bb543;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1;
  letter-spacing: 0 !important;
  text-align: center;
  border: 0.6px solid #4bb543;
  border-radius: 4px !important;
  &:hover,
  &:active,
  &:focus {
    background: #f2f2f2;
    border: 0.6px solid #4bb543;
    color: #4bb543;
  }
}
.btn-sync {
  min-width: 100px;
  height: auto;
  min-height: 20px;
  padding: 6px 5px;
  color: #5162fd;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1;
  letter-spacing: 0 !important;
  text-align: center;
  border: 0.6px solid #5162fd;
  border-radius: 4px !important;
  &:hover,
  &:active,
  &:focus {
    background: #f2f2f2;
    border: 0.6px solid #5162fd;
    color: #5162fd;
  }
}
.btn-processing {
  min-width: 100px;
  height: auto;
  min-height: 20px;
  padding: 6px 5px;
  color: #f28b0c;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1;
  letter-spacing: 0 !important;
  text-align: center;
  border: 0.6px solid #f28b0c;
  border-radius: 4px !important;
  &:hover,
  &:active,
  &:focus {
    background: #f2f2f2;
    border: 0.6px solid #f28b0c;
    color: #f28b0c;
  }
}
.el-badge__content.is-fixed {
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  background: #273480;
  top: 3px;
  height: 16px;
  right: calc(-5px + var(--el-badge-size) / 2);
  transform: translateY(-50%) translateX(100%);
}
.validation-button {
  display: block;
  margin-bottom: -5px;
  img {
    width: 25px;
  }
}
</style>
